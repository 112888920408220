@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-newsfeed-event-teaser {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    border-bottom: 1px solid #ebebeb;
    background: #fff;
    padding: 30px 0;
}

.bt-newsfeed-event-teaser__category {
    span {
        color: #fff;
    }
}
.bt-newsfeed-event-teaser__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
}
.bt-newsfeed-event-teaser__image-container {
    margin-left: 30px;
    a {
        text-decoration: none;
    }
}
.bt-newsfeed-event-teaser__picture {
    flex: 0 0 60px;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

.bt-newsfeed-event-teaser__info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bt-newsfeed-event-teaser__info {
    padding: 5px 30px 0 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    a {
        text-decoration: none;
    }

    .bt-newsfeed-event-teaser__title {
        margin-top: 0;
        display: flex;
        flex-flow: row;
        svg {
            width: 40px;
            padding-top: 3px;
            padding-left: 10px;
            color: $colorSky;
        }
    }

    .bt-newsfeed-event-teaser__datewrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        color: inherit;
        font-size: 16px;
        line-height: 23px;
        font-weight: 300;
        text-decoration: none;
        width: 150px;

        .bt-newsfeed-event-teaser__dateicon {
            color: $colorBlue;
            margin-right: 10px;
            padding-top: 4px;
            width: 20px;
        }
        .bt-newsfeed-event-teaser__date {
            color: $colorBlue;
        }
        .bt-newsfeed-event-teaser__datetime {
            color: $colorGrey;
            text-align: right;
        }
    }
}

.bt-newsfeed-event-teaser__options {
    display: flex;
    flex-direction: column;

    .bt-newsfeed-event-teaser__location {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        svg {
            padding-right: 5px;
        }
    }
}

.bt-newsfeed-event-teaser__button-details-mobile {
    display: none;
}

.bt-newsfeed-event-teaser__content-middle {
    // padding-left: 50px;
    padding-left: 100px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bt-newsfeed-event-teaser__content-lower {
    padding: 0 20px 0 103px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.hide-large {
        display: none;
    }
}

.bt-newsfeed-event-teaser__attend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .bt-event-attendees__image-group {
        padding-top: 10px;
        padding-bottom: 0;
    }
}

.bt-newsfeed-event-teaser__button-show {
    padding-right: 20px;
    min-width: 70px;
}
@media (max-width: 1200px) {
    .bt-newsfeed-event-teaser__content-middle {
        padding-left: 30px;
    }
}

@media (max-width: 1000px) {
    .bt-newsfeed-event-teaser__content-middle {
        padding-left: 100px;
    }
}

@media (max-width: 783px) {
    .bt-newsfeed-event-teaser__content-middle {
        padding-left: 30px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-newsfeed-event-teaser__show-documents-button {
        padding-top: 15px;
    }
    .bt-newsfeed-event-teaser {
        border-bottom-width: 0;
        padding-bottom: 7px;
    }
    .bt-newsfeed-event-teaser__content-lower {
        padding-right: 0;
        &.hide-large {
            display: flex;
        }
    }

    .bt-newsfeed-event-teaser__info {
        flex-direction: column;
        .bt-newsfeed-event-teaser__datewrapper {
            padding-top: 20px;
            width: auto;
            justify-content: flex-start;
            .bt-newsfeed-event-teaser__datetime {
                &::before {
                    content: '|';
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    .bt-newsfeed-event-teaser__attend {
        padding-top: 20px;
        justify-content: flex-start;
    }
    .bt-newsfeed-event-teaser__label {
        margin-left: 20px;
    }
}
