@import '../../styles/import-dimensions';

.error-dialog-overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 30px;
    box-sizing: border-box;
    background: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
