@import url('https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext');
@import './import-colors';

html {
    min-height: 100vh;
}

body {
    background: #fff;
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    font-family: 'Barlow', sans-serif;
    color: $colorBlue;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    overscroll-behavior: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
}
