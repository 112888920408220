@import '../../../styles/import-colors';
@import "../../../styles/import-dimensions";

.bt-label-xxl {
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    color: $colorBlue;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-label-xxl {
        font-size: 24px;
        line-height: 29px;
    } 
}
