@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-event-schedule__time-item {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
}

.bt-event-schedule__time {
    width: 90px;
    // max-width: 150px;
    margin-right: 10px;
}
.bt-event-schedule__time-details {
    flex: 1;
}

@media only screen and (max-width: $breakMobileLandscape) {
}
