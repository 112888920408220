@import "../../../styles/import-colors";
@import "../../../styles/import-dimensions";

.bt-headline-one {
    font-size: 40px;
    line-height: 48px;
    font-weight: 200;
    color: $colorBlue;
    text-transform: uppercase;
    margin: 0;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-headline-one {
        font-size: 30px;
        line-height: 36px;
        font-weight: 300;
    } 
}