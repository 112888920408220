@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-link {
    display: inline-block;
    box-sizing: border-box;
    border: none;
    outline: 0;
    white-space: nowrap;
    color: $colorSky;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        color: $colorBlue;
    }
}
