@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-profile-links {
    display: block;
}

.bt-profile-links__content {
    padding: 50px 20px 0 0;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-links__content {
        padding: 50px 0 0 0;
    }
}

.bt-profile-links__section {
    margin-top: 50px;

    &:first-child {
        margin: 0;
    }

    .bt-profile-links__section-top {
        display: flex;
        flex-flow: row nowrap;

        .bt-profile-links__section-icon {
            flex: 0 0 70px;
        }

        .bt-profile-links__section-icon--mobile {
            flex: 0 0 38px;
            display: none;
        }

        .bt-profile-links__section-title {
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    .bt-profile-links__section-content {
        padding-top: 20px;
        padding-left: 70px;

        .bt-profile-links__section-content-item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-top: 20px;

            &:first-child {
                margin: 0;
            }

            &--cv {
                padding-bottom: 30px;
            }

            div {
                padding-left: 20px;

                &:first-child {
                    padding: 0;
                }
            }
        }
    }

    .bt-profile-links__section-content-links {
        padding-bottom: 30px;
    }

    .bt-profile-links__cv-input-upload {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 1px;
        height: 1px;
    }

    .bt-profile-links__link-input {
        padding-right: 40px;
    }

    .bt-profile-links__buttons {
        margin-top: 30px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        width: 100%;

        .bt-profile-links__button {
            margin-left: 15px;

            &:first-child {
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-links__section {
        .bt-profile-links__section-top {
            .bt-profile-links__section-icon {
                display: none;
            }

            .bt-profile-links__section-icon--mobile {
                display: block;
            }

            .bt-profile-links__section-title {
                height: 28px;
            }
        }

        .bt-profile-links__section-content {
            padding-top: 30px;
            padding-left: 0;
        }
    }
}

.bt-profile-links__inline-error {
    margin-top: 30px;
}
