@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-event-teaser__picture {
    flex: 0 0 60px;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

.bt-event-teaser__category {
    span {
        color: #fff;
    }
}

.bt-event-teaser__info {
    padding-bottom: 20px;

    a {
        text-decoration: none;
    }

    .bt-event-teaser__title {
        margin-top: 0;
        display: flex;
        flex-flow: row;
        align-items: center;
        svg {
            padding-left: 10px;
            color: $colorSky;
        }
    }
}

.bt-button__label {
    font-weight: 300;
}

.bt-event-teaser__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bt-event-teaser__attend-section {
    padding: 40px 0;
    background-color: $colorLightBlueGrey;
    width: 265px;
    flex-shrink: 0;
}
.bt-event-teaser__attend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.bt-event-attendees__image-group {
    padding: 0 0 20px 0;
}

.bt-event-teaser__location {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    padding-right: 50px;
    svg {
        padding-right: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .bt-event-teaser__location {
        padding-right: 0;
        svg {
            flex-shrink: 0;
        }
    }
}

.bt-event-teaser__datelink {
    flex-grow: 1;
    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: inherit;
        font-size: 16px;
        line-height: 23px;
        font-weight: 300;
        text-decoration: none;

        .bt-event-teaser__dateicon {
            color: $colorSky;
            margin-right: 10px;
            padding-top: 4px;
        }
        .bt-event-teaser__date {
            color: $colorSky;
        }
        .bt-event-teaser__datetime {
            color: $colorGrey;
            &::before {
                content: '|';
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .bt-event-teaser__datelink {
        flex-grow: 1;
        a {
            flex-wrap: wrap;
            .bt-event-teaser__datetime {
                width: 100%;
                flex-grow: 1;
                &::before {
                    content: '';
                    margin-left: 32px;
                    margin-right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .bt-event-teaser__options {
        flex-direction: column;
    }

    .bt-event-teaser__attend-section {
        width: auto;
        padding: 20px;
    }

    .bt-event-teaser__attend {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }

    .bt-event-attendees__image-group {
        padding-bottom: 0;
    }
}
