@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-page-view-login {
    max-width: 902px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-login {
        padding: 32px 22px 0 22px;
    }
}

.bt-page-view-login__registration-teaser {
    margin-top: 130px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-login__registration-teaser {
        margin-top: 100px;
    }
}
