@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-teaser-navigation {
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
}

.bt-fellow-teaser-navigation__navigation-item {
    margin: 0 10px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bt-fellow-teaser-navigation__item-list {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bt-fellow-teaser-navigation__navigation-item-label {
        color: $colorGrey;
    }

    .active {
        .bt-fellow-teaser-navigation__navigation-item-label {
            color: $colorBlue;
        }
        .bt-fellow-teaser-navigation__ind {
            display: block;
        }
    }
}
.bt-fellow-teaser-navigation__ind {
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 100%;
    height: 1px;
    background: $colorBlue;
    display: none;
}

.bt-fellow-teaser-navigation__more-button {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bt-fellow-teaser-navigation__more-button-info {
    display: none;
}

.bt-fellow-teaser__new-tag {
    position: absolute;
    top: 0px;
    right: -12px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    background-color: #f04e23;
    letter-spacing: 1px;
    padding: 1px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fellow-teaser-navigation__more-button-text {
        display: none;
    }
    .bt-fellow-teaser-navigation__more-button-info {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
