@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-newsfeed-event-attendees__list-item {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0 30px 30px;
}

.bt-newsfeed-event-attendees__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: $colorBlueGrey;
    border: 0.5px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 120px;
        height: 120px;
        object-fit: cover;
    }
}

.bt-newsfeed-event-attendees__info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.bt-newsfeed-event-attendees__info {
    padding-left: 30px;
    padding-right: 10px;
}

.bt-newsfeed-event-attendees__button-mobile {
    display: none;
}

.bt-newsfeed-event-attendees__no-picture {
    color: white;
    font-size: 30px;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-newsfeed-event-attendees__image {
        width: 100px;
        height: 100px;
    }
    .bt-newsfeed-event-attendees__image {
        width: 60px;
        height: 60px;
        border-radius: 30px;

        img {
            width: 60px;
            height: 60px;
        }
    }

    .bt-newsfeed-event-attendees__list-item {
        h3 {
            font-size: 16px;
        }
    }
    .bt-newsfeed-event-attendees__info {
        padding-left: 10px;
        padding-right: 10px;
    }
    .bt-newsfeed-event-attendees__button {
        display: none;
    }
    .bt-newsfeed-event-attendees__button-mobile {
        display: block;
    }
}
