@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-contact-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
}

.bt-contact-element__picture {
    // flex: 0 0 120px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: $colorLightGrey;
    border: 0.5px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-contact-element {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .bt-button {
            margin: 15px;
        }

        .bt-contact-element__picture {
            margin-bottom: 15px;
        }

        .bt-contact-element__info {
            margin: 15px;
        }
    }
}

@media (max-width: 1000px) {
    .bt-contact-element {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .bt-button {
            margin: 15px;
        }

        .bt-contact-element__picture {
            margin-bottom: 15px;
        }

        .bt-contact-element__info {
            margin: 15px;
        }
    }
}
