@import "../../styles/import-colors";

.bt-page-header-mobile {
    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // border-bottom: 1px solid $colorLightGrey;
    &--show-search {
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.08);
    }
    
    .bt-page-header-mobile__main {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-bottom: 1px solid $colorLightGrey;

        .bt-page-header-mobile__menu {
            flex: 0 0 77px;
            height: 89px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $colorLightGrey;
            cursor: pointer;
        }
    
        .bt-page-header-mobile__title {
            flex-grow: 1;
            padding: 0 16px;
        }
    
        .bt-page-header-mobile__search-button {
            width: 49px;
            height: 38px;
            border-radius: 19px 0 0 19px;
            background: $colorLightGrey;
            display: flex;
            box-sizing: border-box;
            padding-left: 10px;
            color: $colorBlue;
            align-items: center;
            cursor: pointer;

            &--show-search {
                color: $colorSky;
            }
        }
    }

    .bt-page-header-mobile__search {
        padding: 55px 22px;
    } 
}
