@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-page-view-fellows {
    display: block;
}

.bt-page-view-fellows__header-button-desktop {
    display: block;
}

.bt-page-view-fellows__header-button-mobile {
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-fellows__header-button-desktop {
        display: none;
    }

    .bt-page-view-fellows__header-button-mobile {
        display: block;
    }
}

.bt-page-view-fellows__counter {
    margin-top: 50px;
    color: $colorGrey;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-fellows__counter {
        display: none;
    }
}

.bt-page-view-fellows__grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 17px -30px -30px 0;
    box-sizing: border-box;

    .bt-page-view-fellows__grid-item {
        width: 33.333%;
        padding: 0 30px 30px 0;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 1280px) {
    .bt-page-view-fellows__grid {
        .bt-page-view-fellows__grid-item {
            width: 50%;
        }
    }
}

@media only screen and (max-width: 900px) {
    .bt-page-view-fellows__grid {
        margin-right: 0;
        margin-bottom: 0;

        .bt-page-view-fellows__grid-item {
            width: 100%;
            padding: 0 0 30px 0;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-fellows__grid {
        margin-top: 30px;

        .bt-page-view-fellows__grid-item {
            padding: 0;

            &--active {
                padding: 20px 0;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }
}
