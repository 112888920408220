@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-confirm-signup {
    margin-top: -50px;
}
.bt-confirm-signup__cta {
    padding-top: 20px;
}

.bt-confirm-signup__content {
    padding-top: 50px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-contact__cta {
        padding-left: 20px;
    }
}
