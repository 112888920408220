@import "../../styles/import-colors";
@import "../../styles/import-dimensions";

.bt-search-filter {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-search-filter {
        padding: 0 22px;
    }
}

.bt-search-filter__filters {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 20px;
    padding-right: 10px;

    .bt-search-filter__filters-item {
        margin: 0 10px 10px 0;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-search-filter__filters {
        display: none;
    }
}

.bt-search-filter__filters-dropdown {
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-search-filter__filters-dropdown {
        display: block;
    }
}

.bt-search-filter__sorting {
    display: flex;
    align-items: center;
    height: 43px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-search-filter__sorting {
        height: auto;
    }
}
