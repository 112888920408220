@import '../../../styles/import-colors';

.bt-label-m {
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    color: $colorBlue;

    &--high {
        line-height: 23px;
    }
}
