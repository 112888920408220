@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-input__auto-suggest {
    position: relative;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 40px;

    label {
        position: absolute;
        padding-left: 15px;
        top: 0;
        color: #6e6e6e;
        font-size: 14px;
        line-height: 17px;
    }
    input {
        padding: 0 0 0 15px;
        height: 100%;
        width: 100%;
        height: 40px;
        border-radius: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 18px;
        line-height: 22px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #00234c;
        font-family: 'Barlow', sans-serif;

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #6e6e6e;
            font-size: 16px;
            font-style: italic;
        }
    }
    &:focus-within {
        .bt-auto-suggest {
            display: block;
        }
    }
}
.bt-auto-suggest {
    z-index: 99;
    position: absolute;
    top: 70px;
    display: none;
    height: auto;
    min-width: 486px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 30px 23px 30px;
    .bt-auto-suggest__title {
        color: $colorGrey;
        font-weight: 200;
    }
    .bt-auto-suggest__result-list {
        padding-top: 7px;
    }

    .bt-auto-suggest__result {
        padding: 7px 0;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
        cursor: pointer;

        .no-result {
            font-style: italic;
        }
    }
    .bt-auto-suggest__result-title {
        font-weight: 300;
    }
}

.bt-input__search-icon {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.error-autosuggest {
    border-bottom: 1px solid #f04e23;
    border-left: 1px solid #f04e23;
    input {
        color: #f04e23;
        font-size: 16px;
    }

    .bt-input__error-message {
        position: absolute;
        top: 70px;
        left: 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: #f04e23;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
}
