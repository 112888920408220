@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.page-view-forgot-password {
    max-width: 902px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .page-view-forgot-password {
        padding: 0 22px 0 22px;
    }
}

.page-view-forgot-password__text {
    margin-top: 30px;
}

.page-view-forgot-password__form {
    margin-top: 40px;
    margin-bottom: 60px;
}

.page-view-forgot-password__contact {
    margin-top: 50px;
}

.page-view-forgot-password__confirmation {
    border-radius: 5px;
    border-left: 3px solid rgb(0, 35, 76);
    margin: 20px 0;
    padding: 10px;
    display: flex;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    svg {
        padding: 5px 10px 10px 0px;
    }
}

.page-view-forgot-password__error {
    border-radius: 5px;
    border-left: 3px solid red;
    margin: 20px 0;
    padding: 10px;
    display: flex;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    svg {
        padding: 5px 10px 10px 0px;
    }
}
