@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-about-us {
    background: white;
}
.bt-about-us__main-header {
    padding-bottom: 60px;
}
.bt-about-us__sub-header {
    padding-bottom: 30px;
}

.bt-about-us__upper-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;

    .bt-about-us__paragraph1 {
        width: 40%;
        padding-right: 50px;
    }
    .bt-about-us__image-container {
        width: 40%;
        img {
            height: 437px;
            width: 437px;
            position: relative;
            top: -120px;
            margin-bottom: -120px;
        }
    }
}

@media (max-width: 1000px) {
    .bt-about-us__upper-container {
        .bt-about-us__paragraph1 {
            padding-right: 20px;
        }
        .bt-about-us__image-container {
            img {
                height: 300px;
                width: 300px;
                top: -120px;
                margin-bottom: -120px;
            }
        }
    }
}

.bt-about-us__testimonial {
    width: 70%;
    margin-top: 60px;
    padding: 50px 0 50px 50px;
    background: repeating-linear-gradient(
        -45deg,
        $colorBlue,
        $colorBlue 0.5px,
        transparent 1px,
        transparent 7px
    );

    h3 {
        padding-top: 30px;
    }
}

.bt-about-us__testimonial-inner {
    background: white;
    padding: 30px 0 30px 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-about-us__outer-container {
        padding: 20px;
    }
    .bt-about-us__upper-container {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .bt-about-us__testimonial-inner {
        padding: 30px;
    }

    .bt-about-us__testimonial {
        width: 100%;
        padding: 25px 0 25px 25px;
    }

    .bt-about-us__upper-container {
        .bt-about-us__paragraph1 {
            width: 100%;
            padding-right: 0px;
        }
        .bt-about-us__image-container {
            width: 100%;
            margin-bottom: 20px;
            img {
                top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}
