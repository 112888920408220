@import '../../styles/import-colors';

.bt-search-input {
    position: relative;
    display: flex;
    flex-flow: row nowrap;

    .bt-search-input-icon {
        padding-top: 3px;
        cursor: pointer;
    }

    .bt-search-input-form {
        width: 100%;
        margin-left: 15px;
        padding: 0 0 3px;
        border-bottom: 2px solid $colorBlue;

        form {
            margin: 0;
            padding: 0;
        }

        input {
            border: none;
            outline: none;
            background: transparent;
            padding: 0;
            // width: 200px;
            width: 100%;
            font-family: 'Barlow', sans-serif;
            font-size: 16px;
            line-height: 23px;
            font-weight: 300;
            color: $colorBlue;

            &::placeholder {
                color: $colorBlue;
            }
        }
    }
}
