@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-button {
    display: inline-block;
    box-sizing: border-box;
    border: none;
    outline: 0;
    white-space: nowrap;
    color: $colorSky;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        color: $colorBlue;
    }

    .bt-button__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .bt-button__icon-left {
        svg {
            vertical-align: center;
        }
    }

    .bt-button__label {
        margin-left: 10px;
        font-size: 16px;
        line-height: 19px;
        white-space: normal;
        word-break: break-all;
    }

    .bt-button__icon-right {
        margin-left: 7px;

        svg {
            vertical-align: top;
        }
    }

    .bt-button__icon-right-close {
        margin-left: 15px;

        svg {
            vertical-align: top;
        }
    }

    span:first-child {
        margin-left: 0;
    }

    .bt-button__icon-info {
        svg {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &--mobile-side-info {
        background: $colorSky;
        color: #fff !important;
        border-radius: 22px 0 0 22px !important;
        padding: 4px 11px 13px 12px !important;
        height: 44px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: $colorDarkSky;
            text-decoration: none;
        }

        &:active {
            background: $colorLightGrey;
            color: $colorBlue;
        }

        .bt-button__icon-info {
            svg {
                padding-top: 3px;
                padding-bottom: 3px;
            }
        }

        .bt-button__label {
            padding: 3px 0 4px 0;
            white-space: nowrap;
            font-size: 14px;
        }
    }
}

.bt-button--primary {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--primary {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button--primary-side {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--primary-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button__label {
            padding: 16px 0 18px 0;
        }

        .bt-button__icon-info {
            svg {
                padding-top: 16px;
                padding-bottom: 14px;
            }
        }
    }
}

.bt-button--primary-side-small {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px 0 0 22.5px;
    padding: 3px 10px 0 12px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__icon-info {
        svg {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    .bt-button__label {
        padding: 3px 0 4px 0;
        white-space: nowrap;
        font-size: 14px;
    }
}

.xxxxxxbt-button--mobile-side-info {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px 0 0 22.5px;
    padding: 3px 10px 0 12px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__icon-info {
        svg {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    .bt-button__label {
        padding: 3px 0 4px 0;
        white-space: nowrap;
        font-size: 14px;
    }
}

.bt-button--secondary {
    background: #fff;
    color: $colorSky;
    border: 1px solid $colorMidGrey;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        background: $colorLightSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
        border-color: $colorLightGrey;
    }

    .bt-button__label {
        font-weight: 500;
        margin-left: 9px;
        padding: 11px 0 13px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--secondary {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button__label {
            padding: 15px 0 17px 0;
        }
    }
}

.bt-button--secondary-active {
    background: $colorLightGrey;
    color: $colorBlue;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        text-decoration: none;
    }

    .bt-button__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--secondary-active {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button--secondary-side {
    background: $colorLightGrey;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorMidGrey;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 11px 0 13px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--secondary-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button__label {
            padding: 15px 0 17px 0;
        }
    }
}

.bt-button--secondary-side-small {
    background: $colorLightGrey;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;
    height: 34px;

    &:hover {
        background: $colorMidGrey;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 7px 0 8px 0;
        white-space: nowrap;
        font-size: 14px;
    }
}

.bt-button--secondary-white-side {
    background: #fff;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorLightSky;
        text-decoration: none;
    }

    &:active {
        background: #fff;
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--secondary-white-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button--meta-mobile {
    background: transparent;
    color: #fff;
    border-radius: 26.5px;
    padding: 0 22px;

    &:hover {
        text-decoration: none;
    }

    &:active {
        color: $colorBlue;
    }

    .bt-button__label {
        padding: 15px 0 16px 0;
        white-space: nowrap;
    }

    .bt-button__icon-right {
        margin-left: 15px;
    }
}

.bt-button--meta-mobile-active {
    background: $colorBlue;
    color: #fff;
    border-radius: 26.5px;
    padding: 0 22px;

    &:hover {
        text-decoration: none;
    }

    &:active {
        color: #fff;
    }

    .bt-button__label {
        padding: 15px 0 16px 0;
        white-space: nowrap;
    }
}

.bt-button--akkordeon-toggle {
    .bt-button__icon-right {
        margin-left: 15px;
    }
}

.bt-button--layer-back {
    .bt-button__label {
        position: relative;
        top: -1px;
        margin-left: 15px;
    }
}

.bt-button--layer-close {
    .bt-button__label {
        position: relative;
        top: -1px;
    }

    .bt-button__icon-right {
        margin-left: 15px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button--akkordeon-toggle {
        .bt-button__label {
            display: none;
        }
    }
}

.bt-button--grey {
    color: $colorGrey;
}

.bt-button-line-break {
    .bt-button__label {
        word-break: normal;
    }
}
