@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-reset-password-form__input {
    margin: 20px 0;
    max-width: 500px;
}

.bt-reset-password-form__submit-button {
    margin: 20px 0;
}
