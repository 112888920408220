@import "../../styles/import-colors";

.bt-page-navigation-item {
    position: relative;

    a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 60px;
        padding-left: 11px;
        text-decoration: none;
        color: $colorGrey;

        &:hover {
            background: $colorVeryLightGrey;
        }
    }

    .bt-page-navigation-item__ind {
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: $colorBlue;
        display: none;
    }

    &.active {
        a {
            color: $colorBlue;
        }

        .bt-page-navigation-item__ind {
            display: block;
        }
    }
}

.bt-page-navigation-item__icon {
    display: inline-block;
    padding-right: 15px;

    svg {
        vertical-align: top;
    }
}

.bt-page-navigation-item__label {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
}
