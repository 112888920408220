@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-select__interior {
    border-bottom: 1px solid $colorLightGrey;
    border-left: 1px solid $colorLightGrey;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    label {
        position: relative;
        padding-left: 15px;
        top: 0;
        color: #6e6e6e;
        font-size: 14px;
        line-height: 17px;
    }
    select {
        cursor: pointer;
        padding: 0 0 0 15px;
        height: 100%;
        width: 100%;
        height: 40px;
        border-radius: 0;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: 'Barlow', sans-serif;
        font-size: 18px;
        line-height: 22px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $colorBlue;

        background: url('../../../assets/svg/icon-arrow-down-12.svg') no-repeat;
        background-position: right center;
        background-size: 16px 16px;
    }
}

.error-select {
    .bt-select__interior {
        border-bottom: 1px solid $colorRed;
        border-left: 1px solid $colorRed;
        select {
            color: $colorRed;
            font-size: 16px;
        }
    }

    .bt-select__error-message {
        position: relative;
        top: 5px;
        left: 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: $colorRed;
    }
}

.unselected {
    select {
        color: $colorDarkGrey;
        font-size: 16px;
        font-style: italic;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
}
