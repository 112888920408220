@import './styles/import-dimensions';

.bt-app__header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
  .bt-app__header-mobile {
    display: block;
  }

  .bt-app__header-mobile--blur {
    filter: blur(10px);
  }
}
