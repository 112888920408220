@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-textarea {
    position: relative;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    label {
        position: absolute;
        padding-left: 15px;
        top: 0;
        color: #6e6e6e;
        font-size: 14px;
        line-height: 17px;
    }
    textarea {
        padding: 20px 0 0 15px;
        height: 100%;
        width: 100%;
        height: 200px;
        border-radius: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 16px;
        line-height: 23px;
        font-weight: 300;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $colorBlue;
        font-family: 'Barlow', sans-serif;

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #6e6e6e;
            font-size: 16px;
            font-style: italic;
        }
    }
}

.bt-textarea__counter {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 23px;
    font-weight: 300;
    color: $colorDarkGrey
}

.error {
    border-bottom: 1px solid #f04e23;
    border-left: 1px solid #f04e23;
    textarea {
        color: #f04e23;
        font-size: 16px;
    }

    .bt-textarea__error-message {
        position: absolute;
        bottom: -25px;
        left: 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: #f04e23;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
}
