@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-toggle {
    position: relative;
    top: 2px;
    height: 27px;

    .bt-toggle__switch {
        display: inline-block;
        height: 25px;
        position: relative;
        width: 55px;
    }

    .bt-toggle__switch input {
        display: none;
    }

    .bt-toggle__slider {
        border: 1px solid $colorMidGrey;
        background-color: white;
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 13px;
        height: 25px;
        width: 55px;

        &:before {
            background-color: $colorMidGrey;
            content: '';
            height: 21px;
            width: 21px;
            position: absolute;
            top: 2px;
            left: 2px;
            border-radius: 50%;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            -o-transition: inherit;
            transition: inherit;
        }
    }
    .bt-toggle__label {
        cursor: pointer;

        &:before {
            content: attr(data-off);
            text-transform: uppercase;
            font-size: 12px;
            line-height: 23px;
            font-weight: 300;
            color: $colorBlue;
            position: absolute;
            top: 0px;
            right: 8px;
            z-index: 1;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            -o-transition: inherit;
            transition: inherit;
        }
        &:after {
            content: attr(data-on);
            text-transform: uppercase;
            font-size: 12px;
            line-height: 23px;
            font-weight: 300;
            color: $colorBlue;
            position: absolute;
            top: 0;
            left: 8px;
            z-index: 1;
            opacity: 0;
            -webkit-transition: inherit;
            -moz-transition: inherit;
            -o-transition: inherit;
            transition: inherit;
        }
    }

    .bt-toggle__slider,
    .bt-toggle__label {
        transition: All 0.4s ease;
        -webkit-transition: All 0.4s ease;
        -moz-transition: All 0.4s ease;
        -o-transition: All 0.4s ease;
    }
}

input[type='checkbox']:checked ~ .bt-toggle__slider:before {
    background-color: $colorBlue;
    left: 31px;
}

input[type='checkbox']:checked ~ .bt-toggle__label:before {
    opacity: 0;
}
input[type='checkbox']:checked ~ .bt-toggle__label:after {
    opacity: 1;
}

@media only screen and (max-width: $breakMobileLandscape) {
}
