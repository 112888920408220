@import '../../../styles/import-colors';

.bt-wrapper {
    // border: 1px solid red;

    &--margin-top-8 {
        margin-top: 8px;
    }

    &--margin-top-20 {
        margin-top: 20px;
    }

    &--margin-top-30 {
        margin-top: 30px;
    }
}
