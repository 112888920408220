@import "../../styles/import-colors";
@import "../../styles/import-dimensions";

.bt-profile-edit {
    display: block;
}

.bt-profile-edit__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;

    &--layer-bottom {
        padding-top: 80px;
    }

    .bt-profile-edit__button {
        margin-left: 15px;

        &:first-child {
            margin: 0;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-edit__buttons {
        &--layer-bottom {
            padding-top: 40px;
        }
    }
}

.bt-profile-edit__content {
    padding: 30px 20px 0 0;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-edit__content {
        padding: 50px 0 0 0;
    } 
}

.bt-profile-edit__section {
    margin-top: 50px;

    &:first-child {
        margin: 0;
    }

    .bt-profile-edit__section-top {
        display: flex;
        flex-flow: row nowrap;

        .bt-profile-edit__section-icon {
            flex: 0 0 70px;
        }

        .bt-profile-edit__section-icon--mobile {
            flex: 0 0 38px;
            display: none;
        }

        .bt-profile-edit__section-title {
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    .bt-profile-edit__section-content {
        padding-top: 30px;
        padding-left: 70px;
    }

    .bt-profile-edit__row {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        padding-top: 20px;
        box-sizing: border-box;

        &--indent-right {
            padding-right: 20px;
        }

        &:first-child {
            padding-top: 0;
        }

        div {
            flex-grow: 1;
        }

        .bt-profile-edit__row-visibility {
            flex-grow: 0;
        }

        .bt-profile-edit__row-mark {
            position: absolute;
            top: 20px;
            left: -34px;
            color: $colorGrey;
        }

        .bt-profile-edit__row-advice {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-edit__section {
        .bt-profile-edit__section-top {
            .bt-profile-edit__section-icon {
                display: none;
            }

            .bt-profile-edit__section-icon--mobile {
                display: block;
            }

            .bt-profile-edit__section-title {
                height: 28px;
            }
        }

        .bt-profile-edit__section-content {
            padding-top: 30px;
            padding-left: 0;
        }

        .bt-profile-edit__row {
            .bt-profile-edit__row-mark {
                top: 37px;
                left: -25px;
            }
        }
    } 
}
