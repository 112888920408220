@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-profile-data {
  display: block;
}

.bt-profile-data__button-edit {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.bt-profile-data__content {
  padding: 30px 20px 0 0;
}

@media only screen and (max-width: $breakMobileLandscape) {
  .bt-profile-data__content {
    padding: 50px 0 0 0;
  }
}

.bt-profile-data__section {
  margin-top: 50px;

  &:first-child {
    margin: 0;
  }

  .bt-profile-data__section-top {
    display: flex;
    flex-flow: row nowrap;

    .bt-profile-data__section-icon {
      flex: 0 0 70px;
    }

    .bt-profile-data__section-icon--mobile {
      flex: 0 0 38px;
      display: none;
    }

    .bt-profile-data__section-title {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .bt-profile-data__section-info {
    padding-left: 70px;
  }

  .bt-profile-data__employer {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  .bt-profile-data__contact {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  .bt-profile-data__login-item {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.bt-profile-data__link {
  display: flex;
  color: $colorBlue;

  &--disabled {
    color: $colorDarkGrey;
  }

  .bt-profile-data__link-icon {
    flex: 0 0 auto;
    padding-top: 2px;
    padding-right: 10px;
  }

  .bt-profile-data__link-label {
    flex: 0 1 auto;
    font-size: 16px;
    line-height: 23px;
    font-weight: 300;
    word-break: break-all;
  }

  .bt-profile-data__link-visibility {
    flex: 0 0 auto;
    padding-top: 2px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: $breakMobileLandscape) {
  .bt-profile-data__section {
    .bt-profile-data__section-top {
      .bt-profile-data__section-icon {
        display: none;
      }

      .bt-profile-data__section-icon--mobile {
        display: block;
      }

      .bt-profile-data__section-title {
        height: 28px;
      }
    }

    .bt-profile-data__section-info {
      padding-top: 30px;
      padding-left: 0;
    }
  }
}
