@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-news-feed-box-swipe {
    display: block;
}

.bt-news-feed-box-swipe__container {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.bt-news-feed-box-swipe__items {
    transition: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-news-feed-box-swipe__items {
        position: relative;
        left: 0;
        width: 1000%;
        transition: left 0.15s ease;
    }
}

.bt-news-feed-box-swipe--moving {
    .bt-news-feed-box-swipe__items {
        transition: none;
    }
}

.bt-news-feed-box-swipe__item {
    display: block;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-news-feed-box-swipe__item {
        display: table-cell;
        float: left;
        width: 10%;
    }
}

.bt-news-feed-box-swipe__dots {
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-news-feed-box-swipe__dots {
        clear: both;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-top: 1px solid $colorVeryLightGrey;

        .bt-news-feed-box-swipe__dot {
            width: 8px;
            height: 8px;
            border-radius: 5px;
            border: 1px solid $colorBlue;
            margin: 0px 3px;

            &--active {
                background: $colorBlue;
            }
        }
    }
}
