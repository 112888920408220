@import '../../styles/import-colors';

.bt-meta-navigation {
    display: block;

    .bt-meta-navigation__links {
        margin-top: 100px;
    }

    .bt-meta-navigation__item {
        margin-top: 2px;

        &:first-child {
            margin-top: 0;
        }
    }
}
