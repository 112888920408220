@import "../../styles/import-colors";
@import "../../styles/import-dimensions";

.bt-page-content-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 90px;

    .bt-page-content-header-title {
        flex-grow: 1;
        padding: 0 20px 0 0;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-content-header {
        height: 60px;
    
        .bt-page-content-header-title {
            padding: 0 10px 0 22px;
        }
    }
}
