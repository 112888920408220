@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-event-attendees__image {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: $colorLightGrey;
    border: 0.1px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
}

.bt-event-attendees__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1px solid $colorGrey;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: $colorSky;
    flex-shrink: 0;
    cursor: pointer;
    span {
        color: white;
        font-size: 14px;
    }
}

.bt-event-attendees__image-group {
    display: flex;
    flex-direction: row;
    width: 60px;
    padding-right: 10px;
    cursor: pointer;
}
.bt-event-attendees__image-group div:nth-child(2) {
    position: relative;
    left: -15px;
}
.bt-event-attendees__image-group div:nth-child(3) {
    position: relative;
    left: -30px;
}
