@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-profile__item {
    padding-bottom: 50px;
}

.bt-fellow-profile__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 22px;
}

.bt-fellow-profile__icon {
    width: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        height: 40px;
        width: 40px;
    }
}

.bt-fellow-profile__content {
    padding-left: 70px;

    > * {
        padding-bottom: 20px;
    }

    p {
        padding-bottom: 0;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fellow-profile__icon {
        width: 58px;
    }

    .bt-fellow-profile__content {
        padding-left: 0px;
    }
}
