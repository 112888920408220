@import "../../styles/import-dimensions";

.bt-layer-view-header {
    padding: 70px 50px 50px 50px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-view-header {
        padding: 85px 30px 50px 30px;
    } 
}
