@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-layer-navigation-item {
    position: relative;

    a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 50px;
        text-decoration: none;
        color: $colorGrey;

        &:hover {
            color: $colorBlue;
        }
    }

    .bt-layer-navigation-item__ind {
        position: absolute;
        bottom: -1;
        left: 0;
        width: 100%;
        height: 1px;
        background: $colorBlue;
        display: none;
    }

    &.active {
        a {
            color: $colorBlue;
        }

        .bt-layer-navigation-item__ind {
            display: block;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-navigation-item {
        a {
            &:hover {
                color: $colorGrey;
            }
        }

        &.active {
            a {
                color: $colorBlue;
            }
        }
    }
}

.bt-layer-navigation-item__icon {
    display: inline-block;
    padding-right: 15px;

    svg {
        vertical-align: top;
    }
}

.bt-layer-navigation-item__label {
    display: inline-block;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-navigation-item__label {
        font-size: 14px;
        line-height: 17px;
    }
}
