@import "../../styles/import-dimensions";

.bt-mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.bt-mobile-menu-overlay__shader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .2;
    transition: opacity .3s ease;
}

.bt-mobile-menu-overlay__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-mobile-menu-overlay {
        display: block;
    
        &--hidden {
            display: none;
        }
    }
}


