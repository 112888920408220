@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-event-header {
    display: block;
}

.bt-event-header-details {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.bt-event-header-buttons {
    display: flex;
    flex-direction: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-left: 150px;
    height: 45px;
}

.bt-event-header__picture {
    flex: 0 0 120px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: $colorLightGrey;
    border: 0.5px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

.bt-event-header__info {
    flex-grow: 1;
    padding-left: 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-event-header__picture {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }

    .bt-event-header-buttons {
        padding-left: 90px;
    }

    .bt-event-teaser__button-download {
        display: none;
    }
}
