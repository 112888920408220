@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.button-primary {
    border: 0;
    outline: 0;
    background: $colorSky;
    color: white;
    white-space: nowrap;
    border-radius: 26.5px;
    padding: 0 20px;
    cursor: pointer;

    .button-primary__label {
        display: inline-block;
        font-family: 'Barlow', sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        padding: 12px 0 14px 0;
    }

    &:hover {
        background: $colorDarkSky;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    &:disabled {
        border: 1px solid $colorMidGrey;
        background: white;
        color: $colorBlue;
        cursor: default;
        opacity: 0.35;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .button-primary {
        border-radius: 26.5px;
        padding: 0 24px;

        .button-primary__label {
            padding: 16px 0 18px 0;
        }
    }
}
