@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-login-form {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bt-login-form__inputs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.bt-login-form__email {
    padding-right: 15px;
}
.bt-login-form__password {
    padding: 0 15px;
}
.bt-login-form__password-forgot {
    position: relative;
    top: 10px;
    padding-top: 15px;
    padding-left: 20px;
    color: $colorSky;
    font-family: Barlow;
    font-size: 14px;
    text-decoration: none;
}

.bt-login-form__checkbox {
    padding: 0 15px;

    display: flex;
    flex-direction: row;
    span {
        width: 70px;
        color: $colorBlue;
        font-size: 16px;
        font-weight: 300;
    }
}

.bt-login-form__button {
    padding-left: 15px;

    .bt-login-form__submit-button {
        display: none;
    }
}

.bt-login-form__lower-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-login-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .bt-login-form__inputs {
        display: flex;
        flex-direction: column;
    }

    .bt-login-form__email {
        width: 100%;
        padding: 15px;
    }
    .bt-login-form__password {
        width: 100%;
        padding: 15px;
    }
    .bt-login-form__password-forgot {
        padding-left: 0px;
    }

    .bt-login-form__checkbox {
        padding: 0;
    }

    .bt-login-form__button {
        padding: 0;
    }

    .bt-login-form__lower-section {
        padding: 60px 15px 15px 15px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
