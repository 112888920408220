@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-page-view-registration {
    max-width: 902px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-registration {
        padding: 0 22px 0 22px;
    }
}

.bt-page-view-registration__text {
    margin-top: 30px;
}

.bt-page-view-registration__back-button {
    margin-top: 50px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-registration__back-button {
        display: none;
    }
}

.bt-page-view-registration__form {
    margin-top: 15px;
}

.bt-registration-form__submit-button {
    display: none;
}
