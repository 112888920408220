@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-headline-three {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: $colorBlue;
    margin: 0;
}

.events-headline {
    max-width: 140px;
}

// @media only screen and (max-width: $breakMobileLandscape) {
//     .bt-headline-three {
//         font-size: 16px;
//         line-height: 24px;
//     }
// }
