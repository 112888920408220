@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-registration-teaser {
    position: relative;
    // height: 327px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 50px 110px 50px;
    display: flex;
    flex-direction: row;
}

.bt-registration-teaser__title {
    padding-bottom: 30px;
}

.bt-registration-teaser__register {
    padding-right: 15px;
    width: 50%;
}

.bt-registration-teaser__benefits {
    padding-left: 15px;
    width: 50%;
}

.bt-registration-teaser__button {
    position: absolute;
    right: 0;
    bottom: 45px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-registration-teaser {
        flex-direction: column;
        padding: 30px 30px 110px 30px;
    }

    .bt-registration-teaser__register {
        padding-right: 0;
        width: 100%;
        padding-bottom: 30px;
    }

    .bt-registration-teaser__benefits {
        padding-left: 0;
        width: 100%;
    }
    .bt-registration-teaser__button {
        bottom: 30px;
    }
}
