@import '../../../styles/import-colors';

.bt-copy-text-m {
    font-size: 16px;
    line-height: 23px;
    font-weight: 300;
    color: $colorBlue;

    p {
        margin: 15px 0 0 0;

        &:first-of-type {
            margin: 0;
        }
    }

    h2,
    strong,
    b {
        font-weight: 500;
    }

    a {
        color: $colorSky;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--blue {
        color: $colorSky;
    }

    &--grey {
        color: $colorDarkGrey;
    }

    &--heavy {
        font-weight: normal;
    }

    &--bold {
        font-weight: 500;
    }
}
