@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-search-teaser {
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    background: #fff;
    padding: 35px 0 30px 0;

    &--extended {
        padding-bottom: 25px;
    }
}

.bt-search-teaser__top {
    padding: 0 30px;
}

.bt-search-teaser__top-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.bt-search-teaser__top-header-category {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    color: $colorBlue;
    padding-right: 20px;
    
    &-label {
        padding-left: 10px;
    }
}

.bt-search-teaser__top-header-breadcrumb {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    color: $colorGrey;

    &-arrow {
        padding: 0 5px;
    }
}

.bt-search-teaser__top-info {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 25px;

    .bt-search-teaser__top-image {
        flex-grow: 0;
        margin-right: 18px;

        img {
            width: 100px;
            height: auto;
            vertical-align: top;
        }
    }
}

.bt-search-teaser__top-title {
    margin-top: 0;
}

.bt-search-teaser__top-text {
    margin-top: 5px;
}

.bt-search-teaser__bottom {
    padding: 14px 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.bt-search-teaser__bottom-tags {
    padding: 0 0 0 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    color: $colorGrey;
}

.bt-search-teaser__bottom-tag {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin: 0 20px 0 0;
    padding-top: 4px;

    &-label {
        padding-left: 10px;
    }
}

.bt-search-teaser__bottom-button-desktop {
    padding-top: 4px;
}

.bt-search-teaser__bottom-button-mobile {
    padding-bottom: 22px;
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-search-teaser__bottom-button-desktop {
        display: none;
    }
    
    .bt-search-teaser__bottom-button-mobile {
        display: block; 
    }
}
