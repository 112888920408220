@import '../../styles/import-dimensions';

.bt-event-schedule {
    position: relative;
    top: -40px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-event-schedule {
        position: relative;
        top: -20px;
    }
}

.bt-event-schedule__download-button {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-event-schedule__download-button {
        right: auto;
        left: 0;
    }
}

.bt-event-schedule__list {
    padding-top: 30px;
}



