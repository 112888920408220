@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-event-attendee {
    display: flex;
    flex-flow: row nowrap;
}

.bt-event-attendee__link {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-decoration: none;
}

.bt-event-attendee__image {
    flex: 0 0 auto;
}

.bt-event-attendee__info {
    flex-grow: 1;
    padding: 0 30px;
}

.bt-event-attendee__button {
    flex: 0 0 auto;
    padding-top: 14px;

    .bt-event-attendee__button-mobile {
        display: none;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-event-attendee {
        align-items: center;
    }

    .bt-event-attendee__info {
        flex-grow: 1;
        padding: 0 18px;
    }

    .bt-event-attendee__button {
        padding-top: 0;

        .bt-event-attendee__button-desktop {
            display: none;
        }

        .bt-event-attendee__button-mobile {
            display: block;
        }
    }
}
