@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-profile-header {
    display: block;
}

.bt-profile-header-inner {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.bt-profile-header__picture-wrapper {
    position: relative;
    flex: 0 0 auto;

    .bt-profile-header__button-upload {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        width: 38px;
        height: 38px;
        border-radius: 19px;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 10px;
        color: $colorSky;
        background: #fff;
        cursor: pointer;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-header__picture-wrapper {
        .bt-profile-header__button-upload {
            right: -8px;
            bottom: -15px;
        }
    }
}

.bt-profile-header__button-overlay {
    position: absolute;
    z-index: 777;
    top: 81px;
    left: -36px;
    padding: 16px 24px;
    border-radius: 3px;
    background: white;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);

    .bt-profile-header__button-overlay-remove {
        margin-top: 10px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-header__button-overlay {
        top: 36px;
        left: -7px;
    }
}

.bt-profile-header__info {
    flex-grow: 1;
    padding-left: 30px;

    .bt-profile-header__info-profession {
        margin-top: 3px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-profile-header__info {
        width: 60px;
        padding-left: 20px;

        .bt-profile-header__info-profession {
            margin-top: 0;
        }
    }
}
