@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-header {
    display: block;
}

.bt-fellow-header-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.bt-fellow-header__picture {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fellow-header__picture {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }
}

.bt-fellow-header__info {
    flex-grow: 1;
    padding-left: 30px;

    .bt-fellow-header__info-profession {
        margin-top: 3px;
    }

    .bt-fellow-header__info-location {
        margin-top: 3px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fellow-header__info {
        width: 60px;
        padding-left: 20px;

        .bt-fellow-header__info-profession {
            margin-top: 0;
        }

        .bt-fellow-header__info-location {
            margin-top: 0;
        }
    }
}
