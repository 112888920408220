@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.inline-error-message {
    display: flex;
    flex-flow: row nowrap;
    color: $colorRed;

    .inline-error-message__icon {
        flex: 0 0 20px;
        margin-right: 10px;

        svg {
            vertical-align: top;
        }
    }

    .inline-error-message__text {
        span {
            font-family: 'Barlow', sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
    }
}
