@import '../../../styles/import-colors';
@import "../../../styles/import-dimensions";

.bt-label-l {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: $colorBlue;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-label-l {
        font-size: 16px;
        line-height: 24px;
    } 
}
