@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-downloads-group {
    background: #fff;
    border-top: 1px solid $colorLightGrey;
}

.bt-downloads-group__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 15px 180px;
    cursor: pointer;
}

.bt-downloads-group__header-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .bt-downloads-group__header-title {
        font-size: 18px;
        line-height: 23px;
        font-weight: 300;
        padding: 0 30px;
    }

    .bt-downloads-group__header-btn-toggle {
        display: flex;
        justify-content: flex-end;
        width: 134px;
    }
}

.bt-downloads-group__no-results {
    padding: 240px;
    margin-top: 50px;
}

.bt-downloads-group__content-section-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-group__header {
        padding: 30px 20px;
    }

    .bt-downloads-group__header-group {
        .bt-downloads-group__header-title {
            padding: 0 16px;
        }

        .bt-downloads-group__header-btn-download {
            display: none;
        }

        .bt-downloads-group__header-btn-toggle {
            width: auto;
        }
    }
}

.bt-downloads-group__content {
    padding: 0 30px 50px 180px;

    .bt-downloads-group__content-section {
        // display: flex;
        padding-top: 50px;

        &:first-child {
            padding-top: 33px;
        }

        .bt-downloads-group__content-section-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
        }

        .bt-downloads-group__content-section-items {
            padding-top: 0px;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-group__content {
        padding: 0 20px 24px 20px;

        .bt-downloads-group__content-section {
            padding-top: 40px;

            &:first-child {
                padding-top: 2px;
            }
        }
    }
}
