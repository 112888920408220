@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-toolbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
}

.bt-fellow-toolbar__select-year {
    margin-right: 30px;
    width: 25%;
}

.bt-fellow-toolbar__select-country {
    margin-right: 30px;
    width: 25%;
}

.bt-fellow-toolbar__input-search {
    width: 50%;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fellow-toolbar {
        display: none;
    }
}
