@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-registration-form {
    position: relative;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: row;
}

.bt-registration-form__button {
    position: absolute;
    right: 0;
    bottom: 80px;
}

.bt-registration-form__gender-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    span {
        padding-right: 20px;
        color: $colorBlue;
    }
}

.bt-registration-form__left-section {
    border-right: 1px solid $colorLightGrey;
    width: 50%;
    padding-right: 40px;
    input {
        margin: 15px 0;
    }
    > div {
        margin: 20px 0;
    }
}

.bt-registration-form__right-section {
    width: 50%;
    padding-left: 40px;

    > div {
        margin: 20px 0;
    }
}

.bt-registration-form__terms {
    display: flex;
    align-items: center;
    font-size: 14px;
    span {
        color: $colorBlue;
    }
    a {
        color: $colorSky;
        text-decoration: none;
    }
}
.bt-registration-form__button-mobile {
    display: none;
}

.bt-registration-form__error-message {
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    color: #f04e23;
    position: relative;
    top: -15px;
}

.bt-registration-form__error {
    border-radius: 5px;
    border-left: 3px solid red;
    margin: 20px 0;
    padding: 10px;
    display: flex;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    svg {
        padding: 5px 10px 10px 0px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-registration-form {
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: transparent;
        box-shadow: none;
    }

    .bt-registration-form__back-button {
        display: none;
    }
    .bt-registration-form__button {
        display: none;
    }
    .bt-registration-form__button-mobile {
        display: block;
        text-align: center;
        margin-top: 30px;
    }

    .bt-registration-form__left-section {
        border-right: none;
        width: 100%;
        padding-right: 0px;
    }

    .bt-registration-form__right-section {
        width: 100%;
        padding-left: 0px;

        > div {
            margin: 20px 0;
        }
    }
}
