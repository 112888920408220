.carousel__track-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: lightcoral;
    opacity: 0;
}

.carousel__track {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    max-height: 400px;

    .carousel__slide {
        flex: 0 0 auto;
        max-height: 100%;
    }
}
