@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-newsfeed-status__list-item {
    border-bottom: 1px solid #ebebeb;
    padding: 30px 0 30px 30px;
    display: flex;
    flex-direction: column;
    h3 {
        font-size: 16px;
    }
}

.bt-newsfeed-status__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: $colorBlueGrey;
    border: 0.5px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }
}
.bt-newsfeed-status__status {
    padding-bottom: 10px;
    padding-right: 30px;
}

.bt-newsfeed-status__inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.bt-newsfeed-status__info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    span {
        font-size: 16px;
        line-height: 20px;
    }
}

.bt-newsfeed-status__info {
    padding-left: 30px;
    padding-right: 10px;
}

.bt-newsfeed-status__button-mobile {
    display: none;
}

.bt-newsfeed-status__status {
    font-weight: 300;
    padding-bottom: 15px;
}

.bt-newsfeed-status__no-picture {
    color: white;
    font-size: 16px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-newsfeed-status__image {
        width: 100px;
        height: 100px;
    }
    .bt-newsfeed-status__image {
        width: 60px;
        height: 60px;
        border-radius: 30px;

        img {
            width: 60px;
            height: 60px;
        }
    }

    .bt-newsfeed-status__info {
        padding-left: 10px;
        padding-right: 10px;
    }
    .bt-newsfeed-status__button {
        display: none;
    }
    .bt-newsfeed-status__button-mobile {
        display: block;
    }
}
