@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-teaser-compact {
    height: 80px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    padding: 20px;
    margin: 0 15px;
    cursor: pointer;

    .bt-fellow-teaser__picture {
        // flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px !important;
        height: 60px !important;
        border-radius: 30px;
        background: $colorBlueGrey;
        border: 0.5px solid $colorGrey;
        box-sizing: border-box;
        overflow: hidden;
        object-fit: cover;
        img {
            width: 60px !important;
            height: 60px !important;
            object-fit: cover;
        }
    }

    .bt-fellow-teaser__info {
        // display: none;
        padding-left: 20px;
    }

    .bt-fellow-teaser__sub-info {
        display: flex;
        flex-direction: row;
    }

    .bt-fellow-teaser__body {
        display: none;
    }

    .bt-fellow-teaser-navigation {
        display: none;
    }

    .bt-fellow-teaser__picture {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bt-page-view-fellows__fellow-teaser-mobile {
    display: none;
}

.bt-fellow-teaser__no-picture {
    color: white;
    font-size: 17px;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-fellows__fellow-teaser-mobile {
        display: block;
    }

    .bt-fellow-teaser {
        margin: 0 15px;
    }
}
