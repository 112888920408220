@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-newsfeed-news-teaser {
    padding: 30px;
    border-bottom: solid 1px $colorLightGrey;
}

.bt-newsfeed-news-teaser__intro {
    margin-top: 5px;
}
.bt-newsfeed-news-teaser__headlinelink {
    text-decoration: none;
}
.bt-newsfeed-news-teaser__link {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 23px;
    font-weight: 300;
    a {
        color: $colorSky;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
@media (max-width: 1200px) {
    .bt-newsfeed-news-teaser__intro {
    }
    .bt-newsfeed-news-teaser__link {
    }
}

@media (max-width: 1000px) {
    .bt-newsfeed-news-teaser__intro {
    }
    .bt-newsfeed-news-teaser__link {
    }
}

@media (max-width: 783px) {
    .bt-newsfeed-news-teaser__intro {
    }
    .bt-newsfeed-news-teaser__link {
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-newsfeed-news-teaser__intro {
    }
    .bt-newsfeed-news-teaser__link {
    }
}
