@import '../../styles/import-dimensions';

.bt-layer {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bt-layer__shader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
    transition: opacity 0.3s ease;
}

.bt-layer__canvas-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    min-width: 680px;
    height: 100%;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer__canvas-wrapper {
        width: 91.2%;
        min-width: 0;
    }
}

.bt-layer__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: -20px 0 50px 0 rgba(0, 0, 0, 0.17);
    transition: left 0.3s ease;
}

.bt-layer__canvas-content {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer__canvas-content {
        overflow-y: scroll;
    }
}

.bt-layer.bt-layer--hidden {
    height: 0;
    transition: height 0s 0.3s;

    .bt-layer__shader {
        opacity: 0;
    }

    .bt-layer__canvas {
        left: 100%;
    }
}

.bt-layer__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;

    .bt-layer__header-back {
        padding: 15px 15px 15px 50px;
    }

    .bt-layer__header-close {
        padding: 15px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer__header--profile {
        justify-content: flex-start;
    }

    .bt-layer__header {
        .bt-layer__header-back {
            display: block;
            padding-left: 30px;
        }

        .bt-layer__header-close {
            display: none;
        }
    }
}
