@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-events__select {
    width: 160px;
    padding-left: 17.5px;
}
.bt-events__select-year {
    width: 160px;
    padding-left: 20px;
}

.bt-events {
    padding: 0;
}

.bt-events__filter-desktop {
    display: block;
}

.bt-events__navigation {
    padding-left: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 10;
}

.bt-events__select-mobile {
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-events__header {
        padding-left: 20px;
        padding-right: 20px;
    }

    .bt-events {
        max-width: 100vw;
        box-sizing: border-box;
        padding: 0 22px;
    }

    .bt-events__filter-desktop {
        display: none;
    }

    .bt-events__select {
        padding-left: 20px;
    }

    .bt-events__navigation {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }

    .bt-events__select-year {
        display: none;
    }

    .bt-events__select {
        display: none;
    }

    .bt-events__select-mobile {
        display: block;
    }
}
