@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-button-download {
    display: inline-block;
    box-sizing: border-box;
    border: none;
    outline: 0;
    white-space: nowrap;
    color: $colorSky;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        color: $colorBlue;
    }

    .bt-button-download__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .bt-button-download__icon-left {
        svg {
            vertical-align: center;
        }
    }

    .bt-button-download__label {
        margin-left: 10px;
        font-size: 16px;
        line-height: 19px;
        white-space: normal;
        word-break: break-all;
    }

    .bt-button-download__icon-right {
        margin-left: 7px;

        svg {
            vertical-align: top;
        }
    }

    .bt-button-download__icon-right-close {
        margin-left: 15px;

        svg {
            vertical-align: top;
        }
    }

    span:first-child {
        margin-left: 0;
    }

    .bt-button-download__icon-info {
        svg {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

.bt-button-download--primary {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button-download__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--primary {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button-download__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button-download--primary-side {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button-download__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--primary-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button-download__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button-download--primary-side-small {
    background: $colorSky;
    color: #fff;
    border-radius: 22.5px 0 0 22.5px;
    padding: 3px 10px 0 12px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: $colorDarkSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button-download__icon-info {
        svg {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    .bt-button-download__label {
        padding: 3px 0 4px 0;
        white-space: nowrap;
        font-size: 14px;
    }
}

.bt-button-download--secondary {
    background: #fff;
    color: $colorBlue;
    border: 1px solid $colorMidGrey;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        background: $colorLightSky;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
        border-color: $colorLightGrey;
    }

    .bt-button-download__label {
        margin-left: 9px;
        padding: 11px 0 13px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--secondary {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button-download__label {
            padding: 15px 0 17px 0;
        }
    }
}

.bt-button-download--secondary-active {
    background: $colorLightGrey;
    color: $colorBlue;
    border-radius: 22.5px;
    padding: 0 20px;

    &:hover {
        text-decoration: none;
    }

    .bt-button-download__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--secondary-active {
        border-radius: 26.5px;
        padding: 0 24px;

        .bt-button-download__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button-download--secondary-side {
    background: $colorLightGrey;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorMidGrey;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button-download__label {
        padding: 11px 0 13px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--secondary-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button-download__label {
            padding: 15px 0 17px 0;
        }
    }
}

.bt-button-download--secondary-side-small {
    background: $colorLightGrey;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;
    height: 34px;

    &:hover {
        background: $colorMidGrey;
        text-decoration: none;
    }

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    .bt-button-download__label {
        padding: 7px 0 8px 0;
        white-space: nowrap;
        font-size: 14px;
    }
}

.bt-button-download--secondary-white-side {
    background: #fff;
    color: $colorSky;
    border-radius: 22.5px 0 0 22.5px;
    padding: 0 18px 0 20px;

    &:hover {
        background: $colorLightSky;
        text-decoration: none;
    }

    &:active {
        background: #fff;
        color: $colorBlue;
    }

    .bt-button-download__label {
        padding: 12px 0 14px 0;
        white-space: nowrap;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--secondary-white-side {
        border-radius: 26.5px 0 0 26.5px;
        padding: 0 18px 0 24px;

        .bt-button-download__label {
            padding: 16px 0 18px 0;
        }
    }
}

.bt-button-download--akkordeon-toggle {
    .bt-button-download__icon-right {
        margin-left: 15px;
    }
}

.bt-button-download--layer-back {
    .bt-button-download__label {
        position: relative;
        top: -1px;
        margin-left: 15px;
    }
}

.bt-button-download--layer-close {
    .bt-button-download__label {
        position: relative;
        top: -1px;
    }

    .bt-button-download__icon-right {
        margin-left: 15px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-download--akkordeon-toggle {
        .bt-button-download__label {
            display: none;
        }
    }
}

.bt-button-download--grey {
    color: $colorGrey;
}

.bt-button-download-line-break {
    .bt-button-download__label {
        word-break: normal;
    }
}
