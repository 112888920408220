@import "../../styles/import-colors";
@import "../../styles/import-dimensions";

.bt-profile-delete {
    display: block;
}

.bt-profile-delete__button-delete {
    margin-top: 25px;
}

.bt-profile-delete__confirmation {
    margin-top: 46px;
}

.bt-profile-delete__confirmation-buttons {
    margin-top: 25px;
    display: flex;
    flex-flow: row nowrap;
}

.bt-profile-delete__confirmation-button {
    margin-left: 30px;

    &:first-child {
        margin: 0;
    }
}
