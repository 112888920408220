@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-page-view-search {
    padding-bottom: 70px;
}

.bt-page-view-search__no-results {
    opacity: 0;
    transition: opacity .3s ease;

    &--show {
        opacity: 1;
    } 
}

.bt-page-view-search__content {
    opacity: 0;
    transition: opacity .3s ease;

    &--show {
        opacity: 1;
    }
}

.bt-page-view-search__filter {
    margin-top: 30px;
}

.bt-page-view-search__results {
    margin-top: 50px;
}

.bt-page-view-search__grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 17px -30px -30px 0;
    box-sizing: border-box;

    .bt-page-view-search__grid-item {
        width: 50%;
        padding: 0 30px 30px 0;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: $breakTabletLandscape) {
    .bt-page-view-search__grid {
        .bt-page-view-search__grid-item {
            width: 100%;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-search__no-results {
        padding: 0 30px;
    }
    .bt-page-view-search__grid {
        display: block;
        margin: 0 0 0 0;

        .bt-page-view-search__grid-item {
            margin-top: 20px;
            padding: 0;

            &:first-child {
                margin: 0;
            }
        }
    }
}

.bt-page-view-search__more {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
