@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-downloads-panel {
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
}

.bt-downloads-panel__header {
    padding: 30px;

    .bt-downloads-panel__header-top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .bt-downloads-header__picture {
        flex: 0 0 auto;
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: $colorLightGrey;
        border: 0.5px solid $colorGrey;
        box-sizing: border-box;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-panel__header {
        padding: 15px 20px 15px 20px;

        .bt-downloads-header__picture {
            width: 84px;
            height: 84px;
            border-radius: 42px;
        }
    }
}

.bt-downloads-header__info {
    flex-grow: 1;
    padding-left: 30px;

    .bt-downloads-header__info-date {
        margin-top: 3px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
    }

    .bt-downloads-header__info-description {
        margin-top: 3px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-header__info {
        padding-left: 15px;

        .bt-downloads-header__info-description {
            display: none;
        }
    }
}

.bt-downloads-header__info-description--mobile {
    display: none;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-header__info-description--mobile {
        display: block;
        margin-top: 17px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-panel--compact {
        .bt-downloads-header__info-description--mobile {
            display: none;
        }
        .bt-downloads-panel__content {
            display: none;
        }
    }
}
