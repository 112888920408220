$colorBlue: #00234c;
$colorBlueGrey: #587992;
$colorLightBlueGrey: #eef1f4;

$colorSky: #2182c9;
$colorDarkSky: #0361a5;
$colorLightSky: #f6f8f9;

$colorDarkGrey: #6e6e6e;
$colorGrey: #8e9295;
$colorMidGrey: #cfd1d2;
$colorLightGrey: #ebebeb;
$colorVeryLightGrey: #f5f5f5;

$colorGreen: #4abc3c;
$colorLightGreen: #75ee66;

$colorRed: #f04e23;

$lightGreyGradient: linear-gradient(180deg, #fcfcfc 0%, #eeeeee 100%);
