@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-button-checked {
    display: inline-block;
    box-sizing: border-box;
    border: none;
    outline: 0;
    white-space: nowrap;
    color: $colorBlue;
    text-decoration: none;

    .bt-button-checked__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .bt-button-checked__label {
        margin-right: 10px;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;
        word-break: break-all;
    }

    span:first-child {
        margin-left: 0;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-button-checked {
        .bt-button-checked__icon-left {
        }

        .bt-button-checked__label-wrapper {
        }
    }
}
