@import '../../styles/import-dimensions';

.bt-layer-view-body {
    padding: 20px 15px 70px 130px;

    &--event {
        padding: 60px 23px 70px 50px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-view-body {
        padding: 15px 15px 70px 30px;

        &--event {
            padding: 50px 23px 70px 30px;
        }
    }
}
