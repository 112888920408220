@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-image-thumb {
    position: relative;
    background: $colorBlueGrey;
    box-sizing: border-box;
    overflow: hidden;

    .bt-image-thumb__profile-initials {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $colorBlueGrey;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 300;
        color: #fff;
    }

    .bt-image-thumb__profile-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-animation: imageThumbFadeIn 0.5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: imageThumbFadeIn 0.5s; /* Firefox < 16 */
        -ms-animation: imageThumbFadeIn 0.5s; /* Internet Explorer */
        -o-animation: imageThumbFadeIn 0.5s; /* Opera < 12.1 */
        animation: imageThumbFadeIn 0.5s;
    }

    &--size-xs {
        width: 30px;
        height: 30px;
        border-radius: 15px;

        .bt-image-thumb__profile-initials {
            font-size: 10px;
        }
    }

    &--size-s {
        width: 36px;
        height: 36px;
        border-radius: 18px;

        .bt-image-thumb__profile-initials {
            font-size: 12px;
        }
    }

    &--size-m {
        width: 60px;
        height: 60px;
        border-radius: 30px;

        .bt-image-thumb__profile-initials {
            font-size: 20px;
        }
    }

    &--size-l {
        width: 120px;
        height: 120px;
        border-radius: 60px;

        .bt-image-thumb__profile-initials {
            font-size: 30px;
        }
    }

    &--size-xl {
        width: 200px;
        height: 200px;
        border-radius: 100px;

        .bt-image-thumb__profile-initials {
            font-size: 50px;
        }
    }

    &--is-image {
        border: 0.5px solid $colorGrey;
    }

    &--is-mobile-menu {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #fff;

        .bt-image-thumb__profile-initials {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-image-thumb {
        &--mobile-size-xs {
            width: 30px;
            height: 30px;
            border-radius: 15px;

            .bt-image-thumb__profile-initials {
                font-size: 10px;
            }
        }

        &--mobile-size-s {
            width: 36px;
            height: 36px;
            border-radius: 18px;

            .bt-image-thumb__profile-initials {
                font-size: 12px;
            }
        }

        &--mobile-size-m {
            width: 60px;
            height: 60px;
            border-radius: 30px;

            .bt-image-thumb__profile-initials {
                font-size: 20px;
            }
        }

        &--mobile-size-l {
            width: 120px;
            height: 120px;
            border-radius: 60px;

            .bt-image-thumb__profile-initials {
                font-size: 30px;
            }
        }
    }
}

@keyframes imageThumbFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes imageThumbFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes imageThumbFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes imageThumbFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes imageThumbFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
