@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-downloads-toolbar {
    // display: flex;
    // flex-direction: row;
    width: 100%;
    margin-top: 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-toolbar {
        display: none;
    }
}
