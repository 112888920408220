@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-status-list {
    padding-top: 50px;
}

.bt-status-list__item {
    margin-top: 50px;
}

.bt-status-list__picture {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: $colorLightGrey;
    border: 0.5px solid $colorGrey;
    box-sizing: border-box;
    overflow: hidden;
    img {
        width: auto;
        height: 100%;
    }
}

.bt-status-list__item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bt-status-list__item-name {
    padding-left: 20px;
}

.bt-status-list__item-status {
    padding-left: 80px;
}

@media only screen and (max-width: $breakMobileLandscape) {
}
