@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-section {    
    &--padding-top-m {
        padding-top: 50px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-section {
        &--padding-top-m {
            padding-top: 20px;
        }
    }
}

