@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-select-simple {
    select {
        cursor: pointer;
        padding: 0 21px 0 0;
        border-radius: 0;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: 'Barlow', sans-serif;
        font-size: 16px;
        line-height: 23px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $colorSky;
        background: url('../../../assets/svg/icon-arrow-down-sky-12.svg') no-repeat;
        background-position: right center;
        background-size: 14px 14px;
    }
}
