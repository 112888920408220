@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-layer-view-event-attendees {
    margin-right: -23px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-view-event-attendees {
        margin-right: -23px;
    }
}
