@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fellow-teaser {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);

    .bt-fellow-teaser__picture {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }

    .bt-fellow-teaser__info {
        text-align: center;
        margin: 20px;
    }

    .bt-fellow-teaser__body {
        padding: 20px;
        text-align: left;
    }
}
.bt-fellow-teaser__contact-element {
    margin: 10px 0;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-fellows__fellow-teaser-normal {
        display: none;
    }
}
