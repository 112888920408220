@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-radio-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        color: #00234c;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
    }
    input {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        border: 1px solid #8e9295;
        border-radius: 15px;
        background: transparent;

        &:focus {
            outline: none;
        }
        &:checked {
            background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Location_dot_black.svg/1024px-Location_dot_black.svg.png')
                50% / 13px no-repeat;
            border: 1px solid #00234c;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
}
