@import '../../styles/import-dimensions';

.bt-page-view-news-feed {
    display: block;
}

.bt-page-view-news__grid {
    display: flex;
    flex-flow: row wrap;
    opacity: 0;
    transition: opacity 0.3s ease;

    &--show {
        opacity: 1;
    }

    .bt-page-view-news__grid-col--full {
        flex: 0 0 100%;
        box-sizing: border-box;
        padding: 0 0 0 15px;
        margin-bottom: 30px;
    }

    .bt-page-view-news__grid-col {
        flex: 0 0 50%;
        box-sizing: border-box;
        padding: 0 0 0 15px;

        &:first-child {
            padding: 0 15px 0 0;
        }

        .bt-page-view-news__grid-col-item {
            margin-top: 30px;

            &:first-child {
                margin: 0;
            }
        }
    }
}
.bt-page-view-news__button_container {
    margin: 30px 0 30px 30px;
}

.bt-page-view-news__events-mobile {
    display: none;
}
@media (max-width: $breakMobileLandscape) {
    .bt-page-view-news__events {
        display: none;
    }
    .bt-page-view-news__events-mobile {
        display: block;
    }
    .bt-page-view-news__button_container {
        display: none;
    }
}

@media only screen and (max-width: $breakTabletLandscape) {
    .bt-page-view-news__grid {
        display: block;

        .bt-page-view-news__grid-col--full {
            padding: 0;
        }

        .bt-page-view-news__grid-col {
            padding: 0;

            &:first-child {
                padding: 0;
            }

            .bt-page-view-news__grid-col-item {
                margin: 0;
                padding-bottom: 30px;
            }
        }
    }
}
