@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-page-view-downloads {
    display: block;
}

.bt-page-view-downloads__panels-heading {
    margin-top: 50px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-downloads__panels-heading {
        margin-top: 30px;
        padding: 0 22px;
    }
}

.bt-page-view-downloads__panel {
    margin-top: 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-view-downloads__panel {
        margin-top: 30px;
        padding: 0 22px;
    }
}
