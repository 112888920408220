@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.error-dialog {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 70px 50px 70px 50px;
    box-sizing: border-box;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);

    .error-dialog__text {
        margin-top: 20px;
    }

    .error-dialog__close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .error-dialog {
        padding: 70px 30px;

        .error-dialog__text {
            margin-top: 30px;
        }
    }
}
