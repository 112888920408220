@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.profile-image-dialog {
    position: relative;
    width: 364px;
    height: 584px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    flex-flow: column;

    .profile-image-dialog__heading {
        flex-grow: 0;
        height: 94px;
        padding: 30px;
        box-sizing: border-box;
    }

    .profile-image-dialog__content {
        flex-grow: 1;

        .profile-image-dialog__file {
            padding-top: 25px;
        }

        .profile-image-dialog__dropzone {
            width: 200px;
            height: 200px;
            border-radius: 100px;
            margin: 0 auto;
            border: 1px solid $colorGrey;
            box-sizing: border-box;

            .profile-image-dialog__dropzone-icon {
                width: 40px;
                height: 40px;
                margin: 44px auto 0 auto;

                svg {
                    vertical-align: top;
                }
            }

            .profile-image-dialog__dropzone-text {
                text-align: center;
                margin-top: 20px;
            }

            &--drag-over {
                background: $colorLightGrey;
            }

            &:focus {
                outline: 0;
            }
        }

        .profile-image-dialog__or {
            text-align: center;
            margin-top: 15px;
        }

        .profile-image-dialog__image-thumb-mobile {
            display: none;
        }

        .profile-image-dialog__select {
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 15px;

            .profile-image-dialog__select-file-input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 1px;
                height: 1px;
            }
        }

        .profile-image-dialog__edit {
            .profile-image-dialog__edit-cropper {
                position: relative;
                width: 246px;
                height: 292px;
                border: 1px solid $colorMidGrey;
                background-image: linear-gradient(
                        45deg,
                        $colorMidGrey 25%,
                        transparent 25%
                    ),
                    linear-gradient(-45deg, $colorMidGrey 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, $colorMidGrey 75%),
                    linear-gradient(-45deg, transparent 75%, $colorMidGrey 75%);
                background-size: 10px 10px;
                background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
                margin: 0 auto;
            }

            .profile-image-dialog__edit-zoom {
                width: 248px;
                margin: 10px auto 0 auto;
                display: flex;

                .profile-image-dialog__edit-zoom-icon {
                    flex: 0 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    background: $colorLightGrey;

                    svg {
                        vertical-align: top;
                        color: $colorSky;
                    }
                }

                .profile-image-dialog__edit-zoom-slider {
                    flex-grow: 1;
                    padding: 0 24px;
                }
            }

            .profile-image-dialog__change {
                width: 248px;
                margin: 12px auto 0 auto;
                display: flex;
                justify-content: flex-end;

                button {
                    background: transparent;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    outline: 0;
                    cursor: pointer;
                    font-family: 'Barlow', sans-serif;
                    color: $colorSky;

                    .profile-image-dialog__change-icon {
                        display: inline-block;
                        padding-top: 3px;
                        padding-right: 9px;
                        vertical-align: top;
                        svg {
                            vertical-align: top;
                        }
                    }

                    .profile-image-dialog__change-label {
                        display: inline-block;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 300;
                        vertical-align: top;
                    }

                    &:hover {
                        .profile-image-dialog__change-label {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .profile-image-dialog__options {
        flex-grow: 0;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;

        .profile-image-dialog__option {
            margin-left: 30px;

            &:first-of-type {
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: $breakMobilePortrait) {
    .profile-image-dialog {
        width: 100vw;
        height: 100vh;
        border-radius: 0;

        .profile-image-dialog__content {
            .profile-image-dialog__dropzone {
                display: none;
            }

            .profile-image-dialog__or {
                display: none;
            }

            .profile-image-dialog__image-thumb-mobile {
                display: flex;
                justify-content: center;

                .profile-image-dialog__file-error-mobile {
                    width: 200px;
                    height: 200px;
                    border-radius: 100px;
                    margin: 0 auto;
                    border: 1px solid $colorGrey;
                    box-sizing: border-box;

                    .profile-image-dialog__file-error-mobile-icon {
                        width: 40px;
                        height: 40px;
                        margin: 44px auto 0 auto;

                        svg {
                            vertical-align: top;
                        }
                    }

                    .profile-image-dialog__file-error-mobile-text {
                        text-align: center;
                        margin-top: 20px;
                    }
                }
            }

            .profile-image-dialog__select {
                margin-top: 50px;
            }

            .profile-image-dialog__edit {
                padding-top: 25px;
            }
        }
    }
}

.profile-image-dialog__cropper-crop {
    color: rgba(237, 235, 235, 0.69) !important;
    border: 1px solid $colorMidGrey !important;
}

.profile-image-dialog__slider-root {
    height: 0 !important;
    padding: 0 !important;
}

.profile-image-dialog__slider-rail {
    height: 10px !important;
    border-radius: 5px !important;
    background-color: $colorMidGrey !important;
    opacity: 1 !important;
}

.profile-image-dialog__slider-track {
    height: 10px !important;
    border-radius: 5px !important;
    background-color: $colorMidGrey !important;
}

.profile-image-dialog__slider-thumb {
    width: 30px !important;
    height: 30px !important;
    background-color: $colorVeryLightGrey !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.61) !important;
    margin-top: -10px !important;
    margin-left: -15px !important;
}
