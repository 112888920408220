.bt-event-register {
    display: block;
}

.bt-event-register__title {
    margin-bottom: 30px;
}

.bt-event-register__actions {
    a {
        display: block;
        margin-top: 1em;
    }
}

.bt-event-register__button {
    display: block;
    margin-top: 1em;
}