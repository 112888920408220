@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-layer-navigation {
    position: relative;
    height: 50px;
    border-bottom: 1px solid $colorLightGrey;
}

.bt-layer-navigation__wrapper {
    height: 51px;
    overflow-y: hidden;
}

.bt-layer-navigation__scroller {
    height: 100px;
    overflow-x: auto;
}

.bt-layer-navigation__items {
    float: left;
    height: 80px;
    padding-left: 50px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-navigation__items {
        padding-left: 30px;
    }
}

.bt-layer-navigation__item {
    display: table-cell;
    padding-right: 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-layer-navigation__item {
        padding-right: 18px;

        &:last-child {
            padding-right: 30px;
        }
    }
}

.bt-layer-navigation__arrow-more {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 50px;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
    );

    svg {
        position: relative;
        top: 1px;
    }
}
