@import "../../styles/import-colors";
@import "../../styles/import-dimensions";

.bt-page-title {
    display: block;
    padding-left: 11px;
    text-decoration: none;

    .bt-page-title__main {
        font-size: 19px;
        line-height: 25px;
        font-weight: 300;
        color: $colorBlue;
    }

    .bt-page-title__sub { 
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        color: $colorBlue;
    }

    &--mobile {
        .bt-page-title__main {
            font-size: 14px;
            line-height: 18px;
        }

        .bt-page-title__sub { 
            font-size: 14px;
            line-height: 18px;
            margin-top: 1px;
        }
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-page-title {
        padding: 0;
    } 
}
