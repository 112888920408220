@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fallback-event-icon__label {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    span {
        padding-bottom: 2px;
    }

    &--IFP,
    &--BEEP,
    &--BeEP {
        background-color: $colorBlueGrey;
    }

    &--EHRA {
        background-color: $colorMidGrey;
        span {
            color: $colorBlue !important;
        }
    }
    &--OTHER {
        background: $lightGreyGradient;

        span {
            color: $colorGrey !important;
            font-size: 12px;
        }
    }
    &--large {
        height: 120px;
        width: 120px;
        border-radius: 60px;
        .bt-label-m {
            font-size: 31px;
        }

        span {
            font-size: 20px;
        }
    }
}

.bt-fallback-event-icon__category {
    span {
        color: #fff;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-downloads-panel__header {
        .bt-fallback-event-icon__label {
            &--large {
                height: 84px;
                width: 84px;
                border-radius: 42px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
    .bt-event-header {
        .bt-fallback-event-icon__label {
            &--large {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                span {
                    font-size: 12px;
                }
            }
        }
    }
    // .bt-fallback-event-icon {
    // }
}
