@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-fortgot-password {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bt-fortgot-password__email {
    width: 50%;
}

.bt-fortgot-password__back-button {
    margin: 0 20px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-fortgot-password {
        flex-direction: column;
        align-items: flex-start;
    }

    .bt-fortgot-password__email {
        width: 100%;
        margin-bottom: 30px;
    }

    .bt-fortgot-password__back-button {
        display: none;
    }
}
