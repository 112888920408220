@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.meta-navigation-link {
    display: block;
    position: relative;

    a {
        display: block;
        padding: 6px 0 7px 11px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        text-decoration: none;
        color: $colorBlue;

        &:hover {
            background: $colorVeryLightGrey;
        }

        .meta-navigation-link__icon {
            margin-right: 9px;
            padding-top: 1px;

            svg {
                vertical-align: top;
            }
        }
    }

    .meta-navigation-link__ind {
        position: absolute;
        top: 0;
        right: 0;
        width: 2px;
        height: 100%;
        background: $colorBlue;
        display: none;
    }

    &--active {
        a {
            font-weight: 500;
        }

        .meta-navigation-link__ind {
            display: block;
        }
    }
}
