@import "../../styles/import-colors";

.bt-profile-entry {
    position: relative;
    min-width: 200px;
    min-height: 96px;
    user-select: none;

    .bt-profile-entry__canvas {
        position: absolute;
        top: 0;
        right: 0;
    }

    &--active {
        .bt-profile-entry__canvas {
            background: white;
            box-shadow: 0 0 30px 0 rgba(0,0,0,0.08);
        }
    }
}

.bt-profile-entry__info {
    padding: 30px;

    .bt-profile-entry__info-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;

        .bt-profile-entry__info-name {
            flex: 0 0 auto;
            white-space: nowrap;
        }
        
        .bt-profile-entry__info-picture {
            flex: 0 0 auto;
            margin-left: 15px;
        }
        
        .bt-profile-entry__info-icon {
            flex: 0 0 auto;
            margin-left: 15px;
        }

        &:hover {
            .bt-profile-entry__info-name {
                text-decoration: underline;
            }
        }
    }
}

.bt-profile-entry__options {
    border-top: 2px solid $colorLightGrey;
    padding: 30px;

    .bt-profile-entry__options-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;

        .bt-profile-entry__option {
            margin-left: 30px;

            &:first-child {
                margin: 0;
            }
        }
    }
}

.bt-profile-entry__advice {
    position: absolute;
    top: 96px;
    right: 27px;
    min-width: 180px;
    padding: 30px;
    background: #fff;
    cursor: pointer;

    .bt-profile-entry__advice-triangle {
        position: absolute;
        top: -15px;
        right: 32px;

        svg {
            vertical-align: top;
            color: #fff;
        }
    }
}
