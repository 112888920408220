@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-news-feed-box {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
    max-width: '100%';
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-news-feed-box {
        border-radius: 0;
    }
}

.bt-news-feed-box__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 96px;
    background-color: $colorLightSky;
    border-bottom: 1px solid $colorLightGrey;
}

.bt-news-feed-box__header-title {
    flex-grow: 1;
    padding: 0 30px;
    color: $colorBlue;
}

.bt-news-feed-box__button {
    padding-right: 30px;
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-news-feed-box__header-title {
        padding: 0 22px;
    }
}
