@import '../../../styles/import-colors';
@import '../../../styles/import-dimensions';

.bt-copy-text-l {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: $colorBlue;

    &--bold {
        font-weight: 500;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-copy-text-l {
        font-size: 16px;
        line-height: 24px;
    }
}
