@import '../../styles/import-colors';
@import '../../styles/import-dimensions';

.bt-mobile-menu {
    position: relative;
    width: 100%;
    height: 100%;
}

.bt-mobile-menu__shader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $colorBlue;
    opacity: 0.7;
}

.bt-mobile-menu__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .bt-mobile-menu__canvas-content {
        display: flex;
        flex-flow: column;
        min-height: 100%;
    }
}

@media only screen and (max-width: $breakMobileLandscape) {
    .bt-mobile-menu__canvas {
        overflow-y: scroll;
    }
}

.bt-mobile-menu__main {
    flex: 0 0 275px;
    position: relative;
    width: 283px;
    height: 275px;
    margin: 104px auto 60px auto;
}

.bt-mobile-menu__main-item {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorBlue;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.53);
    cursor: pointer;

    &:active {
        background: $colorLightGrey;
        color: $colorBlue;
    }

    &--active {
        background: $colorBlue;
        color: #fff;
    }

    &--news-feed {
        top: 0;
        left: 0;
    }

    &--events {
        top: 0;
        right: 0;
    }

    &--fellows {
        bottom: 0;
        left: 0;
    }

    &--downloads {
        bottom: 0;
        right: 0;
    }

    &--profile {
        top: 98px;
        left: 101px;
    }

    .bt-mobile-menu__main-item-label {
        position: absolute;
        bottom: -27px;
        color: #fff;
    }
}

.bt-mobile-menu__meta {
    flex-grow: 0;
    margin: 109px 0 74px 0;

    .bt-mobile-menu__meta-item {
        text-align: center;
        margin-top: 19px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &--logged-in {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 19px 0 54px 0;

        .bt-mobile-menu__meta-item {
            margin-top: 0;
        }
    }
}

.bt-mobile-menu__links {
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.15);
    padding: 40px 23px 100px 23px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &--logged-in {
        padding-top: 30px;
    }

    .bt-mobile-menu__links-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        max-width: 400px;
    }

    .bt-mobile-menu__link {
        display: block;
        flex: 0 1 100px;
        color: #fff;
        text-align: center;
        text-decoration: none;

        .bt-mobile-menu__link-icon {
            svg {
                vertical-align: top;
                height: 20px;
            }
        }

        .bt-mobile-menu__link-title {
            margin-top: 7px;

            span {
                font-size: 15px;
                line-height: 18px;
                font-weight: 300;
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

.bt-mobile-menu__close {
    position: absolute;
    top: 24px;
    left: 23px;
    color: #fff;
    cursor: pointer;
}

.bt-mobile-menu__logout {
    position: absolute;
    top: 24px;
    right: 0px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;

    .bt-button__label {
        padding-top: 0;
        padding-bottom: 0;
    }

    .bt-button__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
